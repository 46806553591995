import * as React from "react"
import { withPrefix, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import GabrielImg from "../images/gabriel.jpg"
import KontaktBild from "../images/kontakt2.jpg"






const IndexPage = () => (
  
  <Layout>
      <Helmet>
      <html lang="de" />
          <meta charSet="utf-8" />
          <title>Hausmeisterservice Puscasu - Service für Haus & Garten</title>
          <meta name="robots" content="index, follow"></meta>
          <meta name="description" content="Puscasu. Der &#129351; Hausmeisterservice aus Garding für Eiderstedt, SPO und Nordfriesland. Service rund um Haus und Garten. 	&#9989;  Jetzt Termin vereinbaren! "/>
          <script src={withPrefix('script.js')} type="text/javascript" />

<meta itemprop="name" content="Hausmeisterservice Puscasu"/>
<meta itemprop="description" content="Puscasu. Der Hausmeisterservice aus Garding für Eiderstedt, SPO und Nordfriesland. Service rund um Haus und Garten. Jetzt Termin vereinbaren!"/>



<meta property="og:url" content="https://www.puscasu.de"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Hausmeisterservice Puscasu"/>
<meta property="og:description" content="Puscasu. Der Hausmeisterservice aus Garding für Eiderstedt, SPO und Nordfriesland. Service rund um Haus und Garten. Jetzt Termin vereinbaren!"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Hausmeisterservice Puscasu"/>
<meta name="twitter:description" content="Puscasu. Der Hausmeisterservice aus Garding für Eiderstedt, SPO und Nordfriesland. Service rund um Haus und Garten. Jetzt Termin vereinbaren!"/>


        </Helmet>
   
        <section class="relative w-full bg-top bg-cover bg-cover md:bg-center" id="fullhero" x-data="{ showMenu: false }">
    <div class="absolute inset-0 w-full h-full bg-gray-900 opacity-25"></div>
    <div class="absolute inset-0 w-full h-64 opacity-50 bg-gradient-to-b from-black to-transparent"></div>
    <div class="relative flex items-center justify-between w-full h-20 px-8">

        <Link to="/" class="relative flex items-center h-full pr-6 text-lg font-extrabold text-white"> <StaticImage
      src="../images/puscasu.svg"
      width={80}
      style={{ marginTop: `1.7rem` }}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Logo Puscasu"
    />  </Link>

<nav class="flex flex-wrap items-center justify-between p-4">
        <div class="flex flex-shrink-0 mr-6"></div>
        <div class="block lg:hidden">
          <button class="navbar-burger flex items-center py-2 px-3 text-puscasug rounded border border-puscasug">
            <svg class="fill-current h-3 w-3" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
        
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <div class="navbar-menu hidden lg:flex lg:flex-grow lg:items-center w-full lg:w-auto">
          <div class="lg:ml-auto"><a class="block lg:inline-block mt-4 lg:mt-0 mr-10 text-white hover:text-puscasug" href="#ueber">Über</a><a class="block lg:inline-block mt-4 lg:mt-0 mr-10 text-white hover:text-puscasug" href="#leistungen">Leistungen</a></div>
          <div><a class="inline-block py-3 px-6 mt-4 lg:mt-0 leading-none text-white bg-puscasug hover:bg-indigo-700 font-semibold rounded shadow" href="#kontakt">Kontakt</a></div>
        </div>
      </nav>
        
   
           

      
    </div>
   <div id="moin"  style={{ marginTop: `1.7rem` }} class="relative z-10 max-w-6xl px-10 py-40 mx-auto">
        <div class="flex flex-col items-center h-full lg:flex-row">
            <div class="flex flex-col items-center justify-center w-full h-full lg:w-2/3 lg:items-start">
                <p  class="bg-puscasug inline-block w-auto px-3 py-1 mb-5 text-xs font-medium text-white uppercase  rounded-full bg-gradient-to-br">Hausmeisterservice in Garding und Eiderstedt</p>
                <h1 class="font-extrabold tracking-tight text-center text-white text-7xl lg:text-left xl:pr-32">Ich kümmere mich um Ihr Haus</h1>
                <div class="block">
                          <a href="#kontakt">  <button style={{ marginTop: `2.8rem` }} href="#kontakt" class="bg-puscasug w-full px-2 py-2 font-medium text-white  rounded">Jetzt kontaktieren</button></a>
                        </div>
                       
            </div>
           
        </div>
        
    </div>
   <center ><div class="items-center justify-center">
   <a href="#ueber" ><svg style={{ marginTop: `4.1rem` }} class="content-center animate-bounce w-6 h-6 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
      <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
    </svg></a>
  </div></center>
</section>

        <section id="ueber" class="relative leading-7 text-gray-900 bg-white border-solid">
  <div class="box-border mx-auto border-solid lg:pl-8 max-w-7xl">
    <div class="flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200 lg:flex-row">
      <div class="box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20">
        <h2 class="m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black sm:text-4xl md:text-5xl"> Über mich</h2>
        <p class="mt-2 text-xl text-left border-0 border-gray-200 sm:text-2xl">Werdegang</p>
        <div class="grid gap-4 mt-8 leading-7 border-0 border-gray-200 sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8">
          <div class="box-border flex items-start text-gray-900 border-solid">
            <div  class="flex items-center justify-center w-12 h-12 leading-7 border-0 border-gray-200 rounded-full">
              <p class="box-border m-0 text-xl text-white border-solid">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>
              </p>
            </div>
            <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
              <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">Persönliches</h3>
              <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">Moin. Ich bin Gabriel Puscasu. Gelernter Maurer und Handwerker mit Herzblut. Geboren bin ich am 01. September 1988 in Rumänien.
2007 hat es mich nach Deutschland gezogen. Hier angekommen habe ich zunächst als Landwirtschaftshelfer gearbeitet. Mein Herz schlägt für das Handwerk, weswegen ich eine weitere Ausbildung angefangen habe. Am meisten Spaß machen mir neue Herausforderungen, Ideen umzusetzen, draußen an der frischen Luft arbeiten und der Umgang mit Kunden.
Außerdem gefällt mir das Arbeiten mit Holz.


</p>
            </div>
          </div>
          <div class="box-border flex items-start text-gray-900 border-solid">
            <div  class="flex items-center justify-center w-12 h-12 leading-7  border-0 border-gray-200 rounded-full">
              <p class="box-border m-0 text-xl text-white border-solid">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path></svg>
              </p>
            </div>
            <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
              <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">Berufliches</h3>
              <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">- Ausbildung als Maler, Gas- und Wasserinstallateur in Rumänien (nicht in Deutschland anerkannt)
              <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">- Erfahrung als Landwirtschaftshelfer</p> 
              <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">- Ausbildung zum Maurer von 2013 bis  2016 bei Dornbusch in Garding </p>

 </p>
            </div>
          </div>
          <div class="box-border flex items-start text-gray-900 border-solid">
            <div  class="flex items-center justify-center w-12 h-12 leading-7 border-0 border-gray-200 rounded-full">
              <p class="box-border m-0 text-xl text-white border-solid">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
              </p>
            </div>
            <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
              <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">Stärken</h3>
              <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">Meine Kunden begeistere ich mit Zuverlässigkeit und Pünktlichkeit. Ich gehe auf die Wünsche meiner Kunden ein. Dabei kann ich durch meine Kooperationsbereitschaft und meinen Antrieb, für Sie die beste Lösung zu finden, glänzen.
Meine sorgfältige und gründliche Arbeitsweise zeichnet mich aus und macht meine Kunden glücklich.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 lg:w-1/2">
      
        <img style={{ marginTop: `1.8rem` }} width="100%" src={GabrielImg} alt="Im Portrait: Gabriel Puscasu, Chef des Hausmeisterservice Puscasu aus Garding in Eiderstedt." class="object-cover w-full h-full"/>
     
     
      </div>
    </div>
  </div>
</section>

        <section id="leistungen" class="py-20 bg-white">
    <div class="container max-w-6xl mx-auto">
        <h2 class="text-4xl font-bold tracking-tight text-center">Meine angebotenen Leistungen</h2>
        <p class="mt-2 text-lg text-center text-gray-600">Das erledige ich für Sie</p>
        <div class="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

            <div class="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white rounded">
              
                  <StaticImage
      src="../images/gartenarbeit.jpeg"
      width={600}
     
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Gartenarbeit auf Eiderstedt in St. Peter-Ording und Garding"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Gartenarbeit</h4>
                <p class="text-base text-center text-gray-500">Ein gepflegter Garten kann verzücken. Sei es nun Ihre Feriengäste in Tating und Garding oder Sie selber. Gemeinsam mit Ihnen erstellen wir einen Pflegeplan und kümmern uns zuverlässig um Ihren Garten.</p>
            </div>

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white">
          <StaticImage
      src="../images/trockenbau1.jpg"
      width={600}
   
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Trockenbauarbeiten in Garding, Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Trockenbauarbeit</h4>
                <p class="text-base text-center text-gray-500">Egal ob ein weiteres Kinderzimmer hermuss, oder Sie den Dachboden ausgebaut haben wollen. Wir erschließen für Sie neue Räume und bringen Ihre bestehenden ganz nach Ihren Vorstellungen auf Vordermann.</p>
            </div>


            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white"> 
                    <StaticImage
      src="../images/pflasterungen.jpg"
      width={600}
     
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Pflasterung in Garding, Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Pflasterung</h4>
                <p class="text-base text-center text-gray-500">Ob Weg oder Einfahrt - wir verlegen Ihnen Steine. Natürlich nur auf ihrem Grundstück, nicht in den Weg. Dabei bleibt Ihnen überlassen, welches Material. Wir kümmern uns um die Planung und Umsetzung.</p>
            </div>

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 ">
                  <StaticImage
      src="../images/baumsaege1.jpg"
      width={600}
      
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Baumsägearbeiten auf Eiderstedt in Garding und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Baumsägearbeiten</h4>
                <p class="text-base text-center text-gray-500">Kennen Sie das? Der Gartenbaum kommt Ihrem Haus immer näher. Oder wird krank und könnte weitere Bäume anstecken. Wir stutzen oder fällen Ihre Bäume, wenn notwendig und kümmern uns - falls gewünscht - um die anschließende Entsorgung.</p>
            </div>

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white">
                    <StaticImage
      src="../images/reparatur1.jpg"
      width={600}
      
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Reparaturen in Garding, Eiderstedt und St. Peter-Ording"
    />
    
                </div>
                <h4 class="text-xl font-medium text-gray-700">Reparaturen</h4>
                <p class="text-base text-center text-gray-500">Bei jedem noch so gepflegten Haus ist irgendwann der Zeitpunkt gekommen, an dem Reparaturen notwendig sind. Wir übernehmen diese Arbeit gerne für Sie. Egal ob für Ihr Ferienobjekt in St. Peter-Ording, das Eigenheim in Tating oder Ihre Wohnung auf Eiderstedt.</p>
            </div>

          
            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white"> 
                    <StaticImage
      src="../images/holzarbeiten.jpg"
      width={600}
     
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Aufbauen von Fertigbauteilen in Garding, Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Aufbauen von Fertigbauteilen </h4>
                <p class="text-base text-center text-gray-500">Sei es ein neuer Gartenzaun, oder ein schicker Carport. Ich baue Ihnen gerne aus fertigen Komponenten Ihr Wunschobjekt zusammen. Durch meine routinierten Handgriffe sparen sie sich Ihre kostbare Zeit und können diese effizienter nutzen.</p>  </div>

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white"> 
                    <StaticImage
      src="../images/aufbauen.jpg"
      width={600}
     
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Holzarbeiten in Garding, Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Montage von Fenster & Türen </h4>
                <p class="text-base text-center text-gray-500">Die Rechnung ist einfach: Eiderstedt = Wind. Aber nur auf dem Deich, um den Schafen die Locken zu wickeln. Damit es in Ihrem Haus nicht zieht, sind Fenster und Türen vonnöten. Wir übernehmen den Einbau.</p>  </div>

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white"> 
                    <StaticImage
      src="../images/boden.jpg"
      width={600}
     
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Bodenbeläge in Garding, Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Bodenbeläge</h4>
                <p class="text-base text-center text-gray-500">Darf es neuer Boden sein? Oder muss der bestehende Boden gepflegt werden. Vom funktionalen Boden für Ihre Geschäftsräume in Garding bis zum Landhausdielen-Parkett für ihre Villa in Vollerwiek ist alles möglich. Wir kümmern uns drum. Sie suchen aus, wir verlegen. </p>
            </div>  

            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
                <div class="p-3 text-white">
                  
                    <StaticImage
      src="../images/etcArbeit1.jpg"
      width={600}
     s
      quality={100}
      class={"rounded"}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Handwerksdiensleistung in Garding auf Eiderstedt und St. Peter-Ording"
    />
                </div>
                <h4 class="text-xl font-medium text-gray-700">Und noch viel mehr</h4>
                <p class="text-base text-center text-gray-500">Als Allround-Handwerker biete ich Ihnen noch viele weitere Dienstleistungen rund um Ihr Haus an. Zudem greife ich auf ein breites Netzwerk an Kollegen zurück, sollte Ihr Wunsch nicht zu meinen Kompetenzen zählen. Zögern Sie nicht nachzufragen.</p>
            </div>
        </div>
    </div>
</section>
        <section  id="kontakt" class="flex bg-puscasug flex-col w-full  md:flex-row">
    <div class="flex  flex-col items-center justify-center w-full p-10 md:w-1/2 lg:p-16 xl:p-24">
        <h3 class="max-w-lg text-4xl font-light leading-tight text-white md:text-3xl lg:text-4xl xl:text-5xl">Packen wir es an!</h3>
        <h2 class="max-w-lg text-4xl font-light leading-tight text-white md:text-3xl lg:text-1xl xl:text-2xl">Kontaktieren Sie mich</h2>
        <p class="max-w-lg mt-5 text-white  text-xl md:text-base lg:text-xl">per E-Mail an <span class="underline"><a href="mailto:info@puscasu.de">info@puscasu.de</a></span></p>
        <p class="max-w-lg mt-5 text-white  text-xl md:text-base lg:text-xl">oder telefonisch unter <u><a href="tel:+4917632548658">0176/32 548 658</a></u></p>
    </div>

    <div class="w-full md:w-1/2">
      
        <img width="100%" src={KontaktBild} alt="Gabriel Puscasu nimmt Ihre Anfragen gerne entgegen." class="inset-0 object-cover"/>
   
    </div>
</section>
<script dangerouslySetInnerHTML= {{ __html:`
  /**
   * Dropdown menu
   */
  document.addEventListener('DOMContentLoaded', function() {
      const menus = document.querySelectorAll('.navbar-burger');
      const dropdowns = document.querySelectorAll('.navbar-menu');
  
      if (menus.length && dropdowns.length) {
          for (var i = 0; i < menus.length; i++) {
              menus[i].addEventListener('click', function() {
                  for (var j = 0; j < dropdowns.length; j++) {
                      dropdowns[j].classList.toggle('hidden');
                  }
              });
          }
      }
  });
  

`}} />
   
  </Layout>
)

export default IndexPage
